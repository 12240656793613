import { User } from '@/models';
import { computed, ref } from 'vue';
import * as ApiService from './api';
import * as AuthService from './auth';

const authorizedAsStorageKey = 'AuthorizedAs';
const authorizedAs = ref<string>(localStorage.getItem(authorizedAsStorageKey));

export const user = ref<User>(null);

export const get = async () => {
	if (user.value === null) {
		if (!(await AuthService.isLoggedIn())) return null;
		user.value = await ApiService.get<User>('/auth');
	}

	return user;
};

export const isAdmin = computed<boolean>(() => {
	if (getAuthorizedAs() === 'Host') return false;
	return user.value.roles?.includes('Admin') ?? false;
});

export const getAuthorizedAs = () => {
	if (authorizedAs.value == null && user?.value) {
		setAuthorizedAs(user.value.roles[0]);
	}

	return authorizedAs.value;
};

export const setAuthorizedAs = (val: string) => {
	console.log(val);
	if (!user.value.roles.includes(val))
		throw new Error('Invalid role to authorize as.');

	localStorage.setItem(authorizedAsStorageKey, val);
	authorizedAs.value = val;
};

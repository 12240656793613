
import { defineComponent, onMounted, ref } from 'vue';
import Navigation from './components/Navigation.vue';
import Toolbar from './components/Toolbar.vue';
import Loader from './components/Loader.vue';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import { LoaderService, NavigationService, UserService } from './services';
import { HostGameUrl } from './config/auth';

export default defineComponent({
	components: { Navigation, Toolbar, Loader, Toast, ConfirmDialog },
	setup() {
		const navOpen = NavigationService.isOpen;
		const loggedIn = ref(false);
		const loading = LoaderService.active;
		const loader = LoaderService.invoke();

		onMounted(async () => {
			const user = await UserService.get();
			loggedIn.value = !!user?.value;

			if (loggedIn.value && !user.value?.license) {
				window.location.href = HostGameUrl;
				return;
			}
			LoaderService.clear(loader);
		});

		return { loggedIn, navOpen, loading };
	}
});

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import { handleAuthRoutes } from './handleAuthRoutes';
import { handleAuthRequirement } from './handleAuthRequirement';

export const HomeRoute = Symbol();
export const CategoriesRoute = Symbol();
export const CategoryAddRoute = Symbol();
export const CategoryEditRoute = Symbol();
export const CategoryScenariosRoute = Symbol();
export const ScenarioAddRoute = Symbol();
export const ScenarioEditRoute = Symbol();
export const UsersRoute = Symbol();
export const UserEditRoute = Symbol();
export const UserInviteRoute = Symbol();
export const LicensesRoute = Symbol();
export const LicenseEditRoute = Symbol();
export const ReportsRoute = Symbol();
export const FeedbackRoute = Symbol();

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: HomeRoute,
		component: Home,
		meta: {
			title: 'Home'
		}
	},
	{
		path: '/categories',
		name: CategoriesRoute,
		component: () => import('../views/Categories/List.vue'),
		meta: {
			title: 'Categories',
			authRequired: true
		}
	},
	{
		path: '/categories/new',
		name: CategoryAddRoute,
		component: () => import('../views/Categories/Edit.vue'),
		meta: {
			title: 'Add a Category',
			authRequired: ['Admin']
		}
	},
	{
		path: '/categories/:categoryId(\\d+)',
		name: CategoryEditRoute,
		component: () => import('../views/Categories/Edit.vue'),
		props: true,
		meta: {
			title: 'Edit Category',
			authRequired: ['Admin']
		}
	},
	{
		path: '/categories/:categoryId(\\d+)/scenarios',
		name: CategoryScenariosRoute,
		component: () => import('../views/Scenarios/List.vue'),
		props: true,
		meta: {
			title: 'Scenarios',
			authRequired: true
		}
	},
	{
		path: '/categories/:categoryId(\\d+)/scenarios/new',
		name: ScenarioAddRoute,
		component: () => import('../views/Scenarios/Edit.vue'),
		props: true,
		meta: {
			title: 'Add a Scenario',
			authRequired: ['Admin']
		}
	},
	{
		path: '/scenarios/:scenarioId(\\d+)',
		name: ScenarioEditRoute,
		component: () => import('../views/Scenarios/Edit.vue'),
		props: true,
		meta: {
			title: 'Edit Scenario',
			authRequired: ['Admin']
		}
	},
	{
		path: '/reports',
		name: ReportsRoute,
		component: () => import('../views/Reports/Reports.vue'),
		meta: {
			title: 'Download Reports',
			authRequired: true
		}
	},
	{
		path: '/users',
		name: UsersRoute,
		component: () => import('../views/Users/List.vue'),
		meta: {
			title: 'Manage Users',
			authRequired: ['Admin']
		}
	},
	{
		path: '/users/:userId(\\d+)',
		name: UserEditRoute,
		component: () => import('../views/Users/Edit.vue'),
		props: true,
		meta: {
			title: 'Manage User',
			authRequired: ['Admin']
		}
	},
	{
		path: '/users/invite',
		name: UserInviteRoute,
		component: () => import('../views/Users/Invite.vue'),
		props: true,
		meta: {
			title: 'Invite Facilitator',
			authRequired: ['Admin']
		}
	},
	{
		path: '/licenses',
		name: LicensesRoute,
		component: () => import('../views/Licenses/List.vue'),
		meta: {
			title: 'Manage Licenses',
			authRequired: ['Admin']
		}
	},
	{
		path: '/licenses/:licenseId(\\d+)',
		name: LicenseEditRoute,
		component: () => import('../views/Licenses/Edit.vue'),
		props: true,
		meta: {
			title: 'Manage License',
			authRequired: ['Admin']
		}
	},
	{
		path: '/feedback/',
		name: FeedbackRoute,
		component: () => import('../views/Feedback/Index.vue'),
		props: true,
		meta: {
			title: 'Questions / Feedback'
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('../views/Errors/NotFound.vue'),
		meta: {
			title: 'Page Not Found'
		}
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(handleAuthRoutes);
router.beforeEach(handleAuthRequirement);

export default router;


import { User } from '@/models';
import { UserService } from '@/services';
import { defineComponent, onMounted, ref } from 'vue';
import { CategoriesRoute } from '@/router';
import { HostGameUrl } from '@/config/auth';

export default defineComponent({
	name: 'Home',
	setup() {
		const user = ref<User>(null);

		onMounted(async () => {
			user.value = (await UserService.get()).value;
		});

		return { user, CategoriesRoute, HostGameUrl };
	}
});

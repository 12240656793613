import { ApiService, LoaderService } from '@/services';
import { ObjectDirective } from 'vue';
import { useToast } from 'primevue/usetoast';

export const Download: ObjectDirective = {
	mounted(el: Element, binding) {
		const downloadHandler = async (e: MouseEvent): Promise<void> => {
			e.preventDefault();
			const loaderToken = LoaderService.invoke();

			try {
				await ApiService.download(binding.value);
			} catch (e) {
				const toast = useToast();
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Download failed. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(loaderToken);
			}
		};

		el.addEventListener('click', downloadHandler);
	}
};

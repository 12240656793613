
import { defineComponent } from 'vue';
import Menu from 'primevue/menu';
import { NavigationService } from '@/services';

export default defineComponent({
	components: { Menu },
	setup() {
		const items = NavigationService.items;

		return { items };
	}
});

import { AuthService } from '@/services';

export const handleAuthRoutes = (to, _, next) => {
	if (to.path === '/login') {
		AuthService.handleLoginRedirect()
			.then(() => (window.location.href = '/'))
			.catch(error => {
				console.log(error);
				next('/');
			});

		return;
	}

	if (to.path === '/logout') {
		AuthService.logout();

		return;
	}

	if (to.path === '/logout-callback') {
		AuthService.handleLogoutRedirect()
			.then(() => next('/'))
			.catch(error => {
				console.log(error);
				next('/');
			});

		return;
	}

	next();
};

import { computed, ref } from 'vue';
import * as UserService from './user';
import * as AuthService from './auth';
import {
	CategoriesRoute,
	FeedbackRoute,
	LicensesRoute,
	ReportsRoute,
	UsersRoute
} from '@/router';
import { HostGameUrl } from '@/config/auth';
import { MenuItem } from 'primevue/menuitem';

export const items = computed(() => {
	if (!UserService.user?.value) {
		return [
			{
				label: 'Log In',
				command() {
					AuthService.login();
				}
			}
		];
	}

	const items: MenuItem[] = [
		{
			label: 'Game Settings',
			items: [
				{
					label: 'Categories',
					to: { name: CategoriesRoute }
				}
			]
		}
	];

	const adminLinks = {
		label: 'Administration',
		items: [
			{
				label: 'Reports',
				to: { name: ReportsRoute }
			}
		]
	};

	if (UserService.isAdmin.value) {
		adminLinks.items.push({
			label: 'Users',
			to: { name: UsersRoute }
		});

		adminLinks.items.push({
			label: 'Licenses',
			to: { name: LicensesRoute }
		});
	}

	items.push(adminLinks);

	items.push({
		label: 'Quick Links',
		items: [
			{
				label: 'Play Game',
				url: HostGameUrl,
				target: '_blank'
			},
			{
				label: 'Questions / Feedback',
				to: { name: FeedbackRoute }
			}
		]
	});

	return items;
});

export const isOpen = ref(window.outerWidth > 600);

export const toggle = () => (isOpen.value = !isOpen.value);

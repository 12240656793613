import { UserService } from '@/services';
import { RouteLocationNormalized } from 'vue-router';

export const handleAuthRequirement = async (to: RouteLocationNormalized) => {
	type AuthRequirement = boolean | string[];

	const user = (await UserService.get()).value;
	const authReq: AuthRequirement = to.meta.authRequired as AuthRequirement;

	if (!authReq) return null;

	if (
		(authReq && !user) ||
		(authReq instanceof Array && !authReq.some(e => user?.roles.includes(e)))
	) {
		return '/';
	}
};


import { AuthService, NavigationService, UserService } from '@/services';
import { defineComponent, onMounted, ref } from 'vue';
import Avatar from 'primevue/avatar';
import TieredMenu from 'primevue/tieredmenu';
import { User } from '@/models';
import { useRouter } from 'vue-router';
import { HomeRoute } from '@/router';

export default defineComponent({
	components: {
		Avatar,
		TieredMenu
	},
	setup() {
		const toggleNav = () => NavigationService.toggle();
		const login = () => AuthService.login();
		const menu = ref();
		const user = ref<User>(null);
		const router = useRouter();

		const buildMenuItems = () => {
			const items = [];

			if (user.value?.roles.length > 1) {
				items.push({
					label: () => `View: ${UserService.getAuthorizedAs()}`,
					items: user.value.roles.map(role => ({
						label: role,
						command: () => {
							UserService.setAuthorizedAs(role);
							router.push({ name: HomeRoute });
						}
					}))
				});
				items.push({ separator: true });
			}

			items.push({
				label: 'Sign Out',
				command: AuthService.logout
			});

			return items;
		};

		const profileActions = ref([]);

		const initUser = async () => {
			user.value = (await UserService.get()).value;
		};

		onMounted(async () => {
			if (await AuthService.isLoggedIn()) await initUser();
			profileActions.value = buildMenuItems();
		});

		const toggle = event => {
			menu.value.toggle(event);
		};

		return { menu, user, profileActions, toggleNav, login, toggle };
	}
});

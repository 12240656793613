import { computed, reactive } from 'vue';

const loaders = reactive<Set<symbol>>(new Set());

export const active = computed(() => {
	return loaders.size;
});

export const invoke = (token: symbol = null) => {
	if (!token) token = Symbol();
	loaders.add(token);

	return token;
};

export const clear = (token: symbol) => {
	loaders.delete(token);
};

import { ApiUrl } from '@/config/api';
import axios, { AxiosResponse } from 'axios';
import { UserService } from '.';
import * as AuthService from './auth';

const $api = axios.create({
	baseURL: ApiUrl
});

$api.interceptors.request.use(async config => {
	const accessToken = await AuthService.getIdToken();
	config.headers['Authorization'] = `Bearer ${accessToken}`;
	if (UserService.getAuthorizedAs()) {
		config.headers['X-AuthorizeAs'] = UserService.getAuthorizedAs();
	}
	return config;
});

export const getWithResponse = <T>(
	url: string,
	data?: any
): Promise<AxiosResponse<T>> => $api.get(url, data);

export const get = async <T>(url: string, data?: any): Promise<T> =>
	(await getWithResponse<T>(url, data)).data;

export const postWithResponse = <T>(
	url: string,
	data?: any,
	headers?: Record<string, any>
): Promise<AxiosResponse<T>> => $api.post(url, data, { headers });

export const post = async <T>(
	url: string,
	data?: any,
	headers?: Record<string, any>
): Promise<T> => (await postWithResponse<T>(url, data, headers)).data;

export const putWithResponse = <T>(
	url: string,
	data?: any,
	headers?: Record<string, any>
): Promise<AxiosResponse<T>> => $api.put(url, data, { headers });

export const put = async <T>(
	url: string,
	data?: any,
	headers?: Record<string, any>
): Promise<T> => (await putWithResponse<T>(url, data, headers)).data;

export const removeWithResponse = <T>(
	url: string,
	headers?: Record<string, any>
): Promise<AxiosResponse<T>> => $api.delete(url, { headers });

export const remove = async <T>(
	url: string,
	headers?: Record<string, any>
): Promise<T> => (await removeWithResponse<T>(url, headers)).data;

export const download = async (url: string, customFilename: string = null) => {
	const res = await $api.get(url, { responseType: 'blob' });

	const filename = customFilename ?? res.headers['x-suggested-filename'];
	const type = res.headers['content-type'];
	const blob = new Blob([res.data], { type });

	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = filename;
	link.click();

	URL.revokeObjectURL(link.href);
};
